exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-rating-tsx": () => import("./../../../src/pages/rating.tsx" /* webpackChunkName: "component---src-pages-rating-tsx" */),
  "component---src-templates-activation-template-tsx": () => import("./../../../src/templates/activationTemplate.tsx" /* webpackChunkName: "component---src-templates-activation-template-tsx" */),
  "component---src-templates-mindfulness-template-tsx": () => import("./../../../src/templates/mindfulnessTemplate.tsx" /* webpackChunkName: "component---src-templates-mindfulness-template-tsx" */),
  "component---src-templates-question-template-tsx": () => import("./../../../src/templates/questionTemplate.tsx" /* webpackChunkName: "component---src-templates-question-template-tsx" */)
}

